<template>
  <div class="per-img-file p-2" style="height: 91px">
    <div class="custom-file w-40 h35 mr-2">
      <el-button size="small" class="w-100" @click="upUserImage">
        <i class="iconfont icon-tongyong_2_icontubiao_fill_upload mr-2"></i>
        上传文件
      </el-button>
      <span
        v-if="sfxslzzp"
        @click="$emit('showTwoImg')"
        class="editImg h35 text-primary"
      >
        新白底照查看
      </span>
      <el-upload
        v-show="false"
        class="upload-demo"
        action=""
        :accept="astrict.ext"
        :auto-upload="false"
        :show-file-list="false"
        :on-change="
          (file) => {
            return upImage(file)
          }
        "
      >
        <el-button ref="el-upload" size="small" class="w-100">
          <i class="iconfont icon-tongyong_2_icontubiao_fill_upload mr-2"></i>
          上传文件
        </el-button>
      </el-upload>
    </div>
    <span>{{ astrict.text }}</span>
  </div>
</template>

<script>
import { saveStandardPhoto, saveIdentificationPhoto } from '@/api/userInfo.js'
import { getUploadLimit } from '@/api/index'
import { unitsChange, checkFile, checkImgPx } from '@/libs/common'
export default {
  props: {
    type: Number, //12345
    sfts: Boolean, //是否校验
    shzt: Number,
    tpkd: {
      default: function () {
        return {
          w: 295,
          h: 413
        }
      }
    },
    sfpdx: {
      default: true
    }, //是否校验宽高
    module: String, //模块
    sid: String,
    showTwoImg: {
      default: function () {
        return null
      }
    },
    sfxslzzp: {
      default: function () {
        return false
      }
    }
  },
  components: {},
  data() {
    return {
      astrict: {}
    }
  },
  methods: {
    async upImage(file) {
      file = file.raw
      let result
      if (this.sfpdx) {
        // 判断用户照片像素
        if (!(await checkImgPx(this.tpkd.w, this.tpkd.h, file))) {
          this.$message({
            type: 'error',
            message: '照片未通过系统审核，请下载“照片处理工具”处理后再重新上传!'
          })
          return false
        }
        result = checkFile(file, this.astrict)
      } else {
        result = checkFile(file, this.astrict)
      }
      if (!result.status) {
        this.$message({ type: 'error', message: result.message })
        return false
      }
      let module = this.module
      let form = new FormData()
      form.append('module', module)
      form.append('file', file)
      if (this.sid) {
        form.append('sid', this.sid)
      }
      let k = ''
      let v = ''
      if (this.type == 1) {
        k = 'color'
        if (this.sfts) {
          v = 'whiteNew'
        } else {
          v = 'white'
        }
      } else if (this.type == 2) {
        k = 'color'
        v = 'blue'
      } else if (this.type == 3) {
        k = 'color'
        v = 'red'
      } else if (this.type == 4) {
        k = 'type'
        v = 'A'
      } else if (this.type == 5) {
        k = 'type'
        v = 'B'
      }
      form.append(k, v)
      this.upImg(k, form)
      return false
    },
    upUserImage() {
      let _this = this
      if (this.sfts) {
        this.$alert(
          '照片审核已通过，不再允许更换。如需更换，请到当地考试机构现场提交申请。',
          '提示',
          {
            confirmButtonText: '关闭',
            type: 'warning'
          }
        )
          .then(() => {
            return false
          })
          .catch(() => {})
      } else {
        _this.$refs['el-upload'].$el.click()
      }
    },
    upImg(type, form) {
      let func = saveStandardPhoto
      if (type == 'type') {
        func = saveIdentificationPhoto
      }
      func(form).then((res) => {
        if (res.status) {
          this.$emit('success', res.data)
        }
      })
    },
    getLimit() {
      getUploadLimit({ module: this.module }).then((res) => {
        if (res.status) {
          this.astrict['ext'] = '.' + res.data.extLimit.replaceAll(',', ',.')
          this.astrict['size'] = res.data.sizeLimit
          let size = unitsChange(res.data.sizeLimit)
          this.astrict['text'] =
            '只能上传' + res.data.extLimit + '类型的文件，并且不超过' + size
        }
        this.$forceUpdate()
      })
    }
  },
  mounted() {
    this.getLimit()
  }
}
</script>

<style>
.editImg {
  position: absolute;
  min-width: 100px;
  cursor: pointer;
  text-decoration: underline;
}
</style>
